import { Component, Vue } from "vue-property-decorator";
declare module "vue/types/vue";

@Component
export default class DateHelpers extends Vue {
  protected humanDateTime(date: any) {
    let date_object = new Date(date);
    return date_object.toLocaleString("es-CL", { timeZone: "UTC" });
  }

  protected humanDate(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return date_time.split(" ")[0].slice(0, -1);
  }

  protected humanTime(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return date_time.split(" ")[1];
  }

  protected humanDateMonth(date: any) {
    let date_object = new Date(date);
    let date_time = date_object.toLocaleString("es-CL", { timeZone: "UTC" });
    return (
      date_time.split(" ")[0].split("-")[1] +
      "-" +
      date_time.split(" ")[0].split("-")[2]
    );
  }

  protected birthDate(date: string) {
    return (
      date.split("")[6] +
      date.split("")[7] +
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[3] +
      date.split("")[4] +
      "-" +
      date.split("")[0] +
      date.split("")[1]
    );
  }

  protected correctDate(date: string) {
    return (
      date.split("")[8] +
      date.split("")[9] +
      "-" +
      date.split("")[5] +
      date.split("")[6] +
      "-" +
      date.split("")[0] +
      date.split("")[1] +
      date.split("")[2] +
      date.split("")[3]
    );
  }
}
